.additional-container {
    padding: 2rem;
    text-align: center;
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom, #373B9C 0%, #131436 100%);
    margin: 0 auto;
    position: relative;
  
    .parent {
      position: absolute;
      bottom: 5%;
      display: flex;
      flex-direction: column;
      gap: 32px;
      justify-content: center;
      align-items: center;
  
      p {
        width: 80%;
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        color: var(--white-color);
        font-family: 'Outfit', sans-serif;
      }
  
      .box-text {
        width: 57%;
        display: flex;
        justify-content: end;
        align-items: center;
        flex-direction: column;
  
        .qoutLeft,
        .qoutRight {
          img {
            width: auto; 
            max-width: 100%; 
            height: auto;
          }
        }
  
        .qoutLeft {
          align-self: flex-start; 
        }
  
        .qoutRight {
          align-self: flex-end; 
        }
  
        p {
          font-weight: 600;
          font-size: 32px;
          line-height: 40px;
        }
      }
    }
  }
  
  @media (max-width: 850px) {
    .additional-container {
      padding: 1.5rem;
      height: 70vh; 
  
      .parent {
        bottom: 20%;
        gap: 20px; 
        
        p {
          width: 90%;
          font-size: 18px;
          line-height: 24px;
        }
  
        .box-text {
          width: 70%; 
  
          p {
            font-size: 28px;
            line-height: 36px;
          }
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    .additional-container {
      display: none; 
    }
  }
  