.hero-container {
  padding: 2rem 2rem 2rem;
  text-align: center;
  width: 100%;
  background-color: var(--primary-color);
  margin: 0 auto;
  position: relative;
  height: 625px;

  .parent {
    .image {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      width: auto;
      max-height: 350.34px;
      width: 739px;
      border-radius: 5px;
      z-index: 1;
    }
  }

  .about_content {
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    h1 {
      width: 50%;
      text-align: start;
      line-height: 60.48px;
      font-size: 35px;
      font-weight: 600;
      color: var(--secondary-color);
    }

    p {
      width: 50%;
      line-height: 28px;
      font-size: 20px;
      font-weight: 400;
      color: var(--gray-color-secondary);
      text-align: left;
    }

    .flex {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media (max-width: 1400px) {
  .hero-container {
    padding: 2rem 1.5rem;
    .parent {
      .image {
        position: absolute;
        top: 110%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 250px;
        width: 600px;
        border-radius: 5px;
        z-index: 1;
      }
      .about_content {
        padding-top: 5rem;
        display: flex;
        flex-direction: column;
        gap: 0;
    
        h1 {
          width: 70%;
          text-align: start;
          line-height: 60.48px;
          font-size: 35px;
          font-weight: 600;
          color: var(--secondary-color);
        }
    
        p {
          width: 50%;
          line-height: 28px;
          font-size: 20px;
          font-weight: 400;
          color: var(--gray-color-secondary);
          text-align: left;
        }
    
        .flex {
          display: flex;
          justify-content: space-between;
        }
      }
    }

  }
}

@media (max-width: 850px) {
  .hero-container {
    padding: 2rem 1.5rem;
    height: auto;

    .parent {
      .image {
        display: none; /* Hide the image on mobile view */
      }
    }

    .about_content {
      gap: 20px;
      padding-top: 5rem;
      h1 {
        width: 90%;
        font-size: 36px;
        line-height: 44px;
      }

      p {
        width: 90%;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 480px) {
  .hero-container {
    padding: 1rem;
    height: auto;

    .parent {
      .image {
        display: none; /* Hide the image on mobile view */
      }
    }

    .about_content {
      gap: 20px;
      padding-top: 2rem;
      h1 {
        margin-top: 50px !important;
        width: 100%;
        font-size: 28px;
        line-height: 36px;
      }

      p {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
