.our-products-container {
    background-color: var(--secondary-color);
    padding: 2rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .header {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 2rem;
      padding: 3rem 7rem 0 7rem;
      text-align: left;
      width: 100%;
  
      p {
        color: var(--white-color);
        margin: 0;
        font-size: 64px;
        line-height: 80.64px;
      }
  
      h3 {
        color: var(--white-color);
        margin: 0;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
      }
  
      @media (max-width: 1024px) {
        padding: 2rem 4rem;
  
        p {
          font-size: 48px;
          line-height: 60px;
          text-align: center;
        }
  
        h3 {
          font-size: 18px;
          line-height: 24px;
          text-align: center;
        }
      }
  
      @media (max-width: 768px) {
        padding: 1rem 2rem;
  
        p {
          font-size: 36px;
          line-height: 48px;
        }
  
        h3 {
          font-size: 16px;
          line-height: 22px;
        }
      }
  
      @media (max-width: 480px) {
        padding: 0 1rem;
  
        p {
          font-size: 28px;
          line-height: 36px;
        }
  
        h3 {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  
    .product-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      width: 100%;
  
      .product-card {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: var(--white-color);
        padding: 2rem 7rem;
        border-radius: 8px;
        transition: box-shadow 0.3s ease;
        gap: 8rem;
  
        &:nth-child(even) {
          flex-direction: row-reverse;
        }
  
        &:hover {
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        }
  
        img {
          width: 500px;
          height: 500px;
          object-fit: cover;
          border-radius: 8px;
        }
  
        .product-info {
          display: flex;
          flex-direction: column;
          gap: 32px;
          width: 60%;
  
          p {
            font-size: 20px;
            line-height: 28px;
            margin: 0;
            color: var(--white-color);
          }
  
          h3 {
            font-size: 48px;
            font-weight: 600;
            line-height: 60.48px;
            margin: 0;
            color: var(--white-color);
  
            &:first-of-type {
              font-weight: bold;
            }
          }
  
          button {
            display: flex;
            justify-content: start;
            margin-top: 1rem;
            background: none;
            border: none;
            cursor: pointer;
            width: 10%;
            transition: transform 0.3s ease, opacity 0.3s ease;
  
            &:hover {
              transform: scale(1.1);
              opacity: 0.8;
            }
  
            img {
              width: 81px;
              height: 82px;
            }
          }
        }
  
        @media (max-width: 1024px) {
          padding: 2rem 4rem;
  
          img {
            width: 100%;
            height: auto;
          }
  
          .product-info {
            width: 100%;
  
            p {
              font-size: 18px;
              line-height: 24px;
            }
  
            h3 {
              font-size: 36px;
              line-height: 48px;
            }
          }
        }
  
        @media (max-width: 768px) {
          flex-direction: column;
          padding: 2rem;
  
          img {
            width: 100%;
            height: auto;
          }
  
          .product-info {
            width: 100%;
  
            p {
              font-size: 18px;
              line-height: 24px;
            }
  
            h3 {
              font-size: 32px;
              line-height: 40px;
            }
  
            button {
              width: 20%;
            }
          }
  
          &:nth-child(even) {
            flex-direction: column;
          }
        }
  
        @media (max-width: 480px) {
          padding: 1rem;
  
          img {
            width: 100%;
            height: auto;
          }
  
          .product-info {
            width: 100%;
  
            p {
              font-size: 16px;
              line-height: 22px;
            }
  
            h3 {
              font-size: 28px;
              line-height: 36px;
            }
  
            button {
              width: 25%;
            }
          }
        }
      }
    }
  }
  