.boxes-row {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }
  
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%; 
    height: 408px; 
    .toghter{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
    }
    p {
        font-size: 64px;
        line-height: 80px;
        font-weight: 600;
        color: var(--white-color);
    }
    h2 {
       font-weight: 400;
       text-align: start !important;

       font-size: 24px;
       line-height: 28px;
      //  font-family:Outfit ; 
       color: var(--white-color);

    }
    
  }


  @media (max-width: 768px) {
    .boxes-row {
      gap: 10px !important; /* Adjust spacing between boxes */
    }
  
    .box {
      flex: 1 1 calc(50% - 10px) !important; /* 50% width minus gap to fit two in one row */
      max-width: calc(50% - 10px) !important; /* Adjust max-width */
      height: 300px !important; /* Adjust height for tablets */
      
      p {
        font-size: 48px !important; /* Adjust font size */
        line-height: 60px !important;
      }
      h2 {
        font-size: 20px !important; /* Adjust font size */
        line-height: 24px !important;
      }
    }
  }
  
@media (max-width: 480px) {
  .boxes-row {
    gap: 5px !important; 
  }

  .box {
    flex: 1 1 calc(50% - 5px) !important; 
    max-width: calc(50% - 5px) !important; 
    height: 200px !important; 
    text-align: center;
    
    p {
      font-size: 24px !important; 
      line-height: 32px !important;
    
    }

    h2 {
      font-size: 14px !important; 
      line-height: 18px !important;
    }

    img {
      width: 40px !important; 
      height: 40px !important; 
    }
  }
}
