.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 2rem;
}




#icon{
width: 200px;
height: 90px;
margin-top: 40px;

}

.modal-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    background: white;
    padding: 4rem;
    border-radius: 8px;
    max-width: 90%;
    height: 750px;
    width: 100%;
    overflow-y: auto;
    text-align: center;
    position: relative;

    p {
        font-size: 18px;
        line-height: 28px;
        color: var(--gray-color-secondary);
        text-align: left;
        max-width: 550px;
}

    h2 {
        font-size: 48px;
        font-weight: 600;
        line-height: 60.48px;
        margin: 0;
        color: var(--secondary-color);
        text-align: left;
        padding-top: 4rem;

        &:first-of-type {
            font-weight: bold;
        }
    }

    .image-container {
        display: flex;
        justify-content: center;
        margin: 1rem 0;

        img {
            border-radius: 8px;
            max-height: 400px;
            width: 80%; 
            object-fit: cover; 
            margin-top: 150px;
        }
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 2rem; /* Increase font size */
        cursor: pointer;
        padding: 10px; /* Add padding to increase hit area */
        z-index: 1100; /* Ensure it is above other content */
        border-radius: 50%; /* Make it a circle for better click area */
        transition: background 0.3s ease;
        
        &:hover {
            background: rgba(0, 0, 0, 0.1); /* Add background on hover for better visibility */
        }
    }

    @media (max-width: 768px) {
        padding: 2rem; 

        h2 {
            font-size: 36px; 
            line-height: 44px;
        }

        p {
            font-size: 16px; 
            line-height: 24px;
        }

        .image-container {
            margin: 0.5rem 0;
        }

        .close-button {
            top: 5px;
            right: 5px;
            font-size: 1.25rem;
        }
    }

    @media (max-width: 480px) {
        padding: 1rem; 

        h2 {
            font-size: 28px; 
            line-height: 36px;
        }

        p {
            font-size: 14px;
            line-height: 22px;
        }

        .image-container img {
            max-height: 300px;
        }

        .close-button {
            font-size: 1rem; 
        }
    }
}

.red-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--blue-color);
    padding: 5rem 2rem;
    margin-top: 2rem;
    text-align: center;
    color: white;
    width: 80%;

    h3 {
        font-size: 50px;
    }

    .desc {
        display: flex;
        justify-content: start;
        flex-direction: column;
        gap: 25px;
        align-items: self-start;
        padding-right: 80px;

        p {
            font-size: 15px;
            text-align: start;
            line-height: 25px;
        }

        button {
            background-color: transparent;
            color: white;
            border: 1px solid;
            padding: 15px;
            border-radius: 4px;
            font-size: 17px;
            cursor: pointer;
            transition: transform 0.3s ease, opacity 0.3s ease;

            &:hover {
                transform: scale(1.1);
                opacity: 0.8;
            }

            &:first-child {
                margin-right: 1rem;
            }

            &:last-child {
                margin-left: 1rem;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 3rem 1rem; 
        width: 90%; 

        h3 {
            font-size: 36px; 
        }

        .desc {
            padding-right: 0; 
            align-items: center; 
        }
    }

    @media (max-width: 480px) {
        padding: 2rem 0.5rem; 

        h3 {
            font-size: 28px; 
        }

        .desc {
            gap: 15px;
            button {
                font-size: 15px; 
                padding: 10px; 
            }
        }
    }
}
