.container {
  padding: 3rem 7rem 0 7rem;

  .header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
    padding-top: 4rem;
    text-align: left;
    width: 100%;

    p {
      margin: 0;
      font-size: 64px;
      line-height: 80.64px;
      color: var(--secondary-color);
      font-weight: 600;
    }
  }
  /* Add this CSS to Services.scss */

  button {
    display: flex;
    justify-content: start;
    background: none; /* Removes the default background */
    border: none; /* Removes the default border */
    padding: 0; /* Removes default padding */
    cursor: pointer; /* Changes cursor to pointer for better UX */
    outline: none; /* Removes any default focus outline */
    display: flex; /* Ensures the image is centered if needed */
    align-items: center;

    img {
      display: block; /* Ensures the image is displayed correctly */
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    .service-icon {
      margin-right:20px;
      max-width: 300px;
    }

    .flex-col {
      max-width: 50%;
      display: flex;
      flex-direction: column;
      gap: 3rem;

      p {
        font-size: 20px;
        // padding: 20px;
        margin-right:20px;
        line-height: 28px;
        color: var(--gray-color-secondary);
        font-weight: 400;
      }
      .service-image {
        object-position: center;
        object-fit: cover;
        max-width: 100%;
        height: auto;
        border-radius: 8px;
      }
    }
  }

  .line-break {
    border-top: 2px solid;
    color: var(--line-break-color);
    padding: 2rem;
  }
}

@media (max-width: 1200px) {
  .container {
    padding: 2rem 5rem 0 5rem;

    .header p {
      font-size: 48px;
      line-height: 60px;
    }

    .flex {
      .service-icon {
        max-height: 80px;
      }

      .flex-col {
        max-width: 60%;

        p {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
    .flex {
      flex-direction: column;
      align-items: center;
    }
  
    .flex-col {
      max-width: 100%;
      align-items: center;
      text-align: center;
    }
  
    .service-image {
      object-position: center;
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }
  

@media (max-width: 992px) {
  .container {
    padding: 2rem 3rem 0 3rem;

    .header p {
      font-size: 40px;
      line-height: 50px;
    }

    .flex {
      .service-icon {
        max-height: 70px;
      }

      .flex-col {
        max-width: 70%;

        p {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1rem 2rem 0 2rem;

    .header p {
      font-size: 32px;
      line-height: 40px;
    }

    .flex {
      flex-direction: column;
      align-items: center;

      .service-icon {
        max-height: 60px;
      }

      .flex-col {
        max-width: 100%;
        align-items: center;
        text-align: center;

        p {
          font-size: 14px;
          line-height: 20px;
        }

        .service-image {
          width: 100%;
          object-position: center;
          object-fit: cover;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .container {
    padding: 1rem;

    .header p {
      font-size: 24px;
      line-height: 30px;
    }

    .flex {
      .service-icon {
        max-height: 50px;
      }

      .flex-col {
        p {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}
