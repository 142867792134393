.description-section {
    display: flex;
    align-items: start;
    justify-content: center;
    padding: 2rem;
    background-color: var(--white-color);
    gap: 10%;
    margin: 0 auto; // Center align

    &__side-title {
        font-size: 1.5rem;
        color: grey;
        margin-right: 2rem;
        font-weight: 400;
    }

    &__content {
        max-width: 60%;
        font-size: 2.5rem;
        color: #2A2D64; // Adjust this color to match your branding
        line-height: 1.5;
        font-weight: 700;

        p {
            margin: 0;
        }
    }
}

@media (max-width: 1024px) {
    .description-section {
        padding: 1.5rem;
        gap: 5%;

        &__side-title {
            font-size: 1.25rem;
            margin-right: 1.5rem;
        }

        &__content {
            max-width: 70%;
            font-size: 2rem;
        }
    }
}

@media (max-width: 768px) {
    .description-section {
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
        gap: 1rem;

        &__side-title {
            margin-right: 0;
            margin-bottom: 1rem;
            font-size: 1rem;
        }

        &__content {
            max-width: 100%;
            font-size: 1.25rem;
        }
    }
}
