.main-containers {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.info-image-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.info-form-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .main-containers {
    flex-direction: column;
  }

  .info-image-container,
  .info-form-container {
    margin-left: 0;
    margin-top: 10px;
  }

  .info-image-container {
    margin-top: 0;
  }
}