.main-container {
    position: relative;
    .between-image {
      position: absolute;
      top: 57%; 
      left: 50%;
      transform: translate(-50%, -50%);
      height: 779.34px;
      width: 1239px;
      border-radius: 5px;
      z-index: 1;
    }
  }
  