/* InfoBox.scss */

.info-box {
  background-color: #edf2f8;
  padding: 20px;
  margin-bottom: 20px;
  margin-left: 50px;

  &__title {
    font-size: 38px;
    margin-bottom: 10px;
    margin-top: 100px;
    width: 600px;
    color: #373b9c;
  }

  &__text {
    font-size: 14px;
    line-height: 1.6;
    width: 400px;
    color: #808080;
  }
}

/* Media query for tablets */
@media (max-width: 768px) {
  .info-box {
    padding: 15px;
    margin-left: 20px;
  }

  .info-box__title {
    font-size: 32px;
    margin-top: 50px;
    width: 100%;
  }

  .info-box__text {
    font-size: 13px;
    width: 100%;
  }
}

/* Media query for mobile devices */
@media (max-width: 480px) {
  .info-box {
    padding: 10px;
    margin-left: 10px;
  }

  .info-box__title {
    font-size: 28px;
    margin-top: 20px;
    width: 100%;
  }

  .info-box__text {
    font-size: 12px;
    width: 100%;
  }
}