.app__navbar {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  background: var(--secondary-color);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  z-index: 2;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 100px;
    height: 55px;
    @media screen and (min-width: 2000px) {
      width: 180px;
      height: 40px;
    }
  }
}

.app__navbar-links {
  flex: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;

  .navbar-item {
    margin: 0 1rem;
    cursor: pointer;
    position: relative;

    a {
      color: var(--white-color);
      text-decoration: none;
      text-transform: capitalize;
      font-weight: 500;
    }

    &.active::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 2px;
      background-color: var(--white-color);
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);

  svg {
    width: 70%;
    height: 70%;
    color: var(--white-color);
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    padding: 1rem;
    width: 80%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    /* Adjust background for better visibility */
    background-color: var(
      --secondary-color
    ); /* Darker background for contrast */
    /* Optionally, you can remove or modify the background image */
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

    svg {
      width: 35px;
      height: 35px;
      color: var(--white-color); /* Ensure the close button is visible */
      margin: 0.5rem 1rem;
      position: absolute; /* Ensure the close button is properly positioned */
      top: 20px;
      right: 20px;
      cursor: pointer; /* Make sure it's clear the button is clickable */
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }

    .navbar-item {
      margin: 1rem;
      position: relative;

      a {
        color: var(--white-color); /* Ensure text is visible */
        text-decoration: none;
        text-transform: capitalize;
        font-weight: 500;
      }

      &.active::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 100%;
        height: 2px;
        background-color: var(--white-color);
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
}
