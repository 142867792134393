.services-section {
    padding: 2rem;
    background-color: var(--white-color);
    text-align: center;
    margin: 0 auto;
  
    .services-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
      padding: 32px;
      margin-bottom: 2rem;
  
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 1rem;
      }
    }
  
    .service-card {
      background-color: var(--card-color);
      padding: 1.5rem;
      padding: 30px;
      position: relative;
      text-align: left;
      transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
      color: var(--white-color);
  
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        background-color: var(--hover-color);
      }
  
      .service-icon {
        width: 40px;
        height: 40px;
        margin-bottom: 0.75rem;
      }
  
      .service-title {
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
      }
  
      .service-description {
        font-size: 0.875rem;
        margin-bottom: 1rem;
      }
  
      .service-link {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        font-size: 0.875rem;
        color: var(--white-color);
        cursor: pointer;
        transition: color 0.3s ease;
        border: 1px solid var(--white-color);
        padding: 8px;
        border-radius: 50%;
      }
    }
  
    .cta-button {
      background-color: #2A2D64;
      color: white;
      border: none;
      border-radius: 8px;
      padding: 1rem 2rem;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #0078d4;
      }
    }
  
    @media (max-width: 1024px) {
      .services-grid {
        grid-template-columns: repeat(2, 1fr);
        padding: 1rem;
      }
    }
  
    @media (max-width: 768px) {
      .services-grid {
        grid-template-columns: 1fr;
        padding: 1rem;
      }
  
      .service-card {
        padding: 15px;
      }
    }
  }
  