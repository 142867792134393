.image-container {
    display: flex;
    justify-content: center;
    align-items: center;  
    width: 50%;              
    height: auto;           
    padding: 20px;          
    box-sizing: border-box; 
    position: absolute;      
    right: 0;                
    top: 0;                 
  }
  
  .image {
    max-width: 100%;         
    height: auto;            
    border-radius: 8px;     
  }