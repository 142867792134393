.app__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 4rem;
    background: var(--secondary-color);
    border-top: 1px solid rgba(255, 255, 255, 0.18);

    .app__footer-logo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
            width: 100px;
            height: 55px;
            @media screen and (min-width: 2000px) {
                width: 180px;
                height: 40px;
            }
        }
    }

    .app__footer-links {
        flex: 2;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        list-style: none;
        flex-wrap: wrap; 
        gap: 20px;

        .footer-item {
            margin: 0 1rem;
            cursor: pointer;
            position: relative;

            a {
                color: var(--white-color);
                text-decoration: none;
                text-transform: capitalize;
                font-weight: 500;
            }
            
            &.active::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -5px;
                width: 100%;
                height: 2px;
                background-color: var(--white-color);
            }
        }

        @media screen and (max-width: 900px) {
            justify-content: center;
        }
    }

    @media screen and (max-width: 900px) {
        flex-direction: column;
        align-items: center;

        .app__footer-logo {
            margin-bottom: 1rem;
        }
    }
}
