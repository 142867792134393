.section {
  padding: 2rem;
  text-align: center;
  width: 100%;
  background-color: var(--primary-color);

  &__content {
    max-width: 900px;
    margin: 0 auto;
    margin-top: 3rem;
    padding: 2rem;

    h1 {
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 1rem;

      .highlight {
        color: var(--secondary-color); // Adjust this color to match your branding
      }
    }

    &__description {
      font-size: 1.125rem;
      color: #666;
      margin-bottom: 2rem;
    }
  }

  &__images {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: relative;
    margin-top: 1rem;

    .section__image {
      width: 100%;
      max-width: 500px;
      height: auto;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      position: relative;
      z-index: 1;
    }

    // TODO: back it later
    // .square {
    //   position: relative;
    //   background-color: #2A2D64; // Adjust this color to match your branding
    //   z-index: 0;
    // }

    // .square-1 {
    //   width: 40rem;
    //   height: 20rem;
    //   top: -2rem;
    //   left: -34rem;
    // }

    // .square-2 {
    //   width: 40rem;
    //   height: 20rem;
    //   bottom: -2rem;
    //   right: -2rem;
    // }
  }
}

@media (max-width: 1024px) {
  .section__content {
    padding: 1.5rem;

    h1 {
      font-size: 2.5rem;
    }

    &__description {
      font-size: 1rem;
    }
  }

  &__images {
    .square-1 {
      width: 20rem;
      height: 20rem;
      top: -1.5rem;
      left: -1.5rem;
    }

    .square-2 {
      width: 20rem;
      height: 20rem;
      bottom: -1.5rem;
      right: -1.5rem;
    }
  }
}

@media (max-width: 1024px) {
  .section__content {
    padding: 1.5rem;

    h1 {
      font-size: 2.5rem;
    }

    &__description {
      font-size: 1rem;
    }
  }

  .section__images {
    flex-direction: column;

    .section__image {
      max-width: 80%;
      margin-bottom: 1rem;
    }

    .square-1 {
      width: 20rem;
      height: 10rem;
      top: -1.5rem;
      left: -1.5rem;
    }

    .square-2 {
      width: 20rem;
      height: 10rem;
      bottom: -1.5rem;
      right: -1.5rem;
    }
  }
}

@media (max-width: 768px) {
  .section__content {
    padding: 1rem;

    h1 {
      margin-top: 20px;
      font-size: 2rem;
    }

    &__description {
      font-size: 0.875rem;
    }
  }

  .section__images {
    flex-direction: column;

    .section__image {
      max-width: 100%;
      margin: 0;
      margin-bottom: 1rem;
    }

    .square-1,
    .square-2 {
      display: none; // Hide squares on mobile
    }
  }
}

@media (max-width: 480px) {
  .section__content {
    padding: 0.5rem;

    h1 {
      font-size: 1.5rem;
    }

    &__description {
      font-size: 0.75rem;
    }
  }

  .section__images {
    flex-direction: column;

    .section__image {
      max-width: 100%;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    .square-1,
    .square-2 {
      display: none; 
    }
  }
}

