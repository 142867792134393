.our-blogs-container {
  background-color: var(--white-color);
  padding: 2rem;
  margin: 0 auto;
  gap: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .see-more-container {
    text-align: center;
    margin-top: 0.05rem;
    background-color: #2A2D64;
    padding: 0.5rem 1rem;
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    &:hover {
      background-color: #195dc9;
    }

  }
  
  .see-more-button {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    text-decoration: none;
  
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  
 
  }
  

  .header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
    padding: 3rem 7rem 0 7rem;
    text-align: left;
    width: 100%;

    p {
      margin: 0;
      font-size: 64px;
      line-height: 80.64px;
      color: var(--secondary-color);
      font-weight: 600;
    }
  }

  .blogs-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    width: 100%;
    margin-bottom: 100px;
    justify-items: center;
    padding: 3rem 7rem 0 7rem;
    align-items: stretch; /* Ensures all items stretch to the same height */

    .blogs-card {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--line-break-color);
      border-radius: 8px;
      overflow: hidden;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      max-width: 450px;
      text-align: center;
      padding: 15px;
      box-sizing: border-box;
      flex: 1; /* Allows the card to grow and shrink */
      display: flex;
      flex-direction: column;
      justify-content: space-between; /* Ensures content is spaced evenly */
      // min-height: 100%; /* Makes sure the card fills the height of the grid cell */
height: 500px !important;
      &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
      }

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }

      .user {
        padding: 1rem;

        .blogs-info {
          display: flex;
          flex-direction: column;
          align-items: self-start;
          gap: 32px;
          text-align: start;

          h3 {
            margin: 0;
            font-size: 24px;
            font-weight: 600;
            line-height: 30.24px;
          }

          p {
            margin: 0;
            padding-top: 15px;
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            color: var(--gray-color-secondary);
          }

          a {
            margin: 0;
            font-size: 20px;
            font-weight: 400;
            line-height: 25.2px;
            padding: 0;
            background: none;
            border: none;
            color: inherit;
            cursor: pointer;
            text-align: left;
            text-decoration: none;
          }

          a:hover {
            color: #007bff;
            text-decoration: underline;
          }
        }
      }

      .line-break {
        width: 80%;
        height: 1px;
        background-color: var(--primary-color);
        margin: 1rem auto;
      }
    }
  }

  @media (max-width: 1024px) {
    .blogs-list {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 768px) {
    .header {
      padding: 2rem;
      p {
        font-size: 48px;
        line-height: 60.48px;
      }
    }

    .blogs-list {
      grid-template-columns: 1fr;
      padding: 2rem;
    }
  }

  @media (max-width: 480px) {
    .header {
      padding: 1rem;
      p {
        font-size: 36px;
        line-height: 48px;
        text-align: center;
      }
    }

    .blogs-list {
      grid-template-columns: 1fr;
      padding: 1rem;
    }

    .blogs-card {
      max-width: 100%;
      padding: 10px;

      img {
        height: 150px;
      }

      .blogs-info {
        gap: 16px;
        text-align: center;
        align-items: center;

        h3 {
          font-size: 18px;
          line-height: 24px;
        }

        p {
          font-size: 16px;
          line-height: 24px;
        }

        h2 {
          font-size: 16px;
          line-height: 22px;
        }

        a {
          margin: 0;
          font-size: 20px;
          font-weight: 400;
          line-height: 25.2px;
          padding: 0;
          background: none;
          border: none;
          color: inherit;
          cursor: pointer;
          text-align: left;
          text-decoration: none;
        }

        a:hover {
          color: #007bff;
          text-decoration: underline;
        }
      }
    }
  }
}
