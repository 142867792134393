.our-clients-container {
    background-color: var(--white-color);
    padding: 2rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 2rem;
        padding: 3rem 7rem 0rem 7rem;
        text-align: left;
        width: 100%;

        p {
            margin: 0;
            font-size: 64px;
            line-height: 80.64px;

            span {
                background-color: var(--secondary-color);
                color: var(--white-color);
            }
        }
        h3 {
            margin: 0;
            font-size: 20px;
            line-height: 28px;
            font-weight: 600;
        }
    }

    .client-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        width: 100%;

        .client-card {
            display: none;
            justify-content: space-between;
            padding: 2rem 7rem 2rem 7rem;
            border-radius: 8px;
            transition: box-shadow 0.3s ease;
            gap: 10rem;

            &.active {
                display: flex;
            }

            &:hover {
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
            }

            img {
                object-fit: cover;
                border-radius: 8px;
                width: 100px;
            }

            .arrow {
                flex-direction: column;
                width: 70%;

                p {
                    font-size: 25px;
                    line-height: 40px;
                    width: 70%;
                }
            }

            .user {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding-bottom: 5rem;

                p {
                    font-size: 15px;
                    margin: 0;
                    width: 100% ; 
                }
            }

            .arrow-buttons {
                flex-direction: column;
                width: 100%;
            }

            .client-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;

                h3 {
                    font-size: 20px;
                    font-weight: 600;
                    margin: 0;

                    &:first-of-type {
                        font-weight: bold;
                    }
                }

                button {
                    display: flex;
                    justify-content: start;
                    margin-top: 1rem;
                    background: none;
                    border: none;
                    cursor: pointer;
                    width: 10%;
                    transition: transform 0.3s ease, opacity 0.3s ease;

                    &:hover {
                        transform: scale(1.1);
                        opacity: 0.8;
                    }

                    img {
                        width: 81px;
                        height: 82px;
                    }
                }
            }
        }
    }

    .carousel-buttons {
        margin-top: 2rem;
        display: flex;

        button {
            background: none;
            border: none;
            cursor: pointer;
            transition: transform 0.3s ease, opacity 0.3s ease;

            &:hover {
                transform: scale(1.1);
                opacity: 0.8;
            }

            &:first-child {
                margin-right: 1rem;
            }

            &:last-child {
                margin-left: 1rem;
            }
        }
    }

    .red-box {
        display: flex;
        justify-content: flex-start; /* Align items to the left */
        align-items: flex-start; /* Align items to the top */
        background-color: var(--blue-color);
        padding: 5rem 2rem;
        margin-top: 2rem;
        text-align: left;
        color: white;
        width: 80%; /* Adjust width as needed */
    
        h3 {
            font-size: 50px;
        }
    
        .desc {
            display: flex;
            flex-direction: column;
            gap: 25px;
            align-items: flex-start; /* Align items to the start of the container */
            padding-right: 80px;
    
            p {
                font-size: 15px;
                text-align: left;
                line-height: 25px;
            }
    
            button {
                background-color: transparent;
                color: white;
                border: 1px solid;
                padding: 15px;
                border-radius: 4px;
                font-size: 17px;
                cursor: pointer;
                transition: transform 0.3s ease, opacity 0.3s ease;
    
                &:hover {
                    transform: scale(1.1);
                    opacity: 0.8;
                }
    
                &:first-child {
                    margin-right: 1rem;
                }
    
                &:last-child {
                    margin-left: 1rem;
                }
            }
        }
    }
    @media (max-width: 768px) {
        padding: 1rem;

        .header {
            padding: 1rem;

            p {
                font-size: 15px;
                line-height: 60px;
            }
        }

        .client-card {
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;
            gap: 2rem;

            &:nth-child(even) {
                flex-direction: column;
            }

            img {
                width: 100%;
                height: auto;
            }

            .client-info {
                align-items: center;
                text-align: center;
            }

            .arrow {
                width: 100%;

                p {
                    font-size: 20px;
                    width: 100%;
                }
            }
        }

        .red-box {
            flex-direction: column;
            gap: 20px;
            padding: 2rem 1rem;
            width: 100%;

            h3 {
                font-size: 30px;
            }

            .desc {
                padding-right: 0;
                align-items: center;
                text-align: center;

                p {
                    font-size: 14px;
                }

                button {
                    font-size: 16px;
                }
            }
        }
    }

    @media (max-width: 480px) {
        .header {
            p {
                font-size: 36px;
                line-height: 48px;
            }
        }
    
        .client-card {

            padding: 1rem !important;
            gap: 0px !important;

            .arrow {
                p {
                    font-size: 15px !important;
                    width: 250px !important;  

                  
                }
            }
    
            .user {
                padding-bottom: 0rem !important;
                gap: 0px !important;

    
                p {
                    font-size: 14px;
                }
            }
    
            .client-info {
                h3 {
                    font-size: 18px;
                }
            }
        }
    
        .red-box {
            padding: 1rem;
    
            h3 {
                font-size: 24px;
            }
    
            .desc {
                p {
                    font-size: 12px;
                }
    
                button {
                    font-size: 14px;
                    padding: 10px;
                }
            }
        }
    }
}
