.our-product-container {
    background-color: var(--white-color);
    padding: 2rem;
    margin: 0 auto; 
    display: flex;
    flex-direction: column;
    align-items: center; 

    .header {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 2rem;
        padding: 3rem 7rem 0rem 7rem;
        text-align: left; 
        width: 100%;

        p {
            color: var(--secondary-color);
            margin: 0;
            font-size: 50px;
            line-height: 63px;
            font-weight: 600;
            padding-top: 4rem;
            width: 80%;
        }

        @media (max-width: 1024px) {
            padding: 2rem 4rem;

            p {
                font-size: 36px;
                line-height: 44px;
                width: 100%;
                text-align: center;
            }
        }

        @media (max-width: 768px) {
            padding: 2rem 2rem;

            p {
                font-size: 28px;
                line-height: 36px;
                width: 100%;
                text-align: center;
            }
        }

        @media (max-width: 480px) {
            padding: 1rem;

            p {
                font-size: 22px;
                line-height: 30px;
                width: 100%;
                text-align: center;
            }
        }
    }

    .product-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        width: 100%; 

        .product-card {
            display: flex;
            flex-direction: row; 
            justify-content: space-between;
            align-items: center;
            color: var(--secondary-color);
            padding: 2rem 7rem;
            border-radius: 8px;
            transition: box-shadow 0.3s ease;
            gap: 8rem;

            &:nth-child(even) {
                flex-direction: row-reverse; 
            }

            &:hover {
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
            }

            img {
                width: 600px;
                height: 600px;
                object-fit: cover;
                border-radius: 8px;
            }

            .product-info {
                display: flex;
                flex-direction: column;
                gap: 32px;
                width: 60%;

                p {
                    font-size: 20px;
                    line-height: 28px;
                    margin: 0;
                    color: var(--gray-color-secondary);
                }

                h3 {
                    font-size: 48px;
                    font-weight: 600;
                    line-height: 60.48px;
                    margin: 0;
                    color: var(--secondary-color);

                    &:first-of-type {
                        font-weight: bold;
                    }
                }

                h4 {
                    display: flex;
                    justify-content: flex-end;
                    padding-top: 4rem;
                    font-size: 117.65px;
                    color: var(--opacity-secondary-color);
                }

                button {
                    display: flex;
                    justify-content: start;
                    margin-top: 1rem;
                    background: none;
                    border: none;
                    cursor: pointer;
                    width: 10%;
                    transition: transform 0.3s ease, opacity 0.3s ease; 

                    &:hover {
                        transform: scale(1.1);
                        opacity: 0.8; 
                    }

                    img {
                        width: 81px;
                        height: 82px;
                    }
                }
            }

            @media (max-width: 1024px) {
                padding: 2rem 4rem; 

                img {
                    width: 100%;
                    height: auto;
                }

                .product-info {
                    width: 100%; 

                    p {
                        font-size: 18px; 
                        line-height: 24px; 
                    }

                    h3 {
                        font-size: 36px; 
                        line-height: 48px;
                    }

                    h4 {
                        font-size: 90px; 
                    }
                }

                &:nth-child(even) {
                    flex-direction: row; 
                }
            }

            @media (max-width: 768px) {
                flex-direction: column; 
                padding: 2rem; 

                img {
                    width: 100%;
                    height: auto;
                }

                .product-info {
                    width: 100%; 

                    p {
                        font-size: 18px; 
                        line-height: 24px; 
                    }

                    h3 {
                        font-size: 36px; 
                        line-height: 48px;
                    }

                    h4 {
                        font-size: 80px;
                    }
                }

                &:nth-child(even) {
                    flex-direction: column; 
                }
            }

            @media (max-width: 480px) {
                padding: 1rem; 

                img {
                    width: 100%;
                    height: auto;
                }

                .product-info {
                    width: 100%; 

                    p {
                        font-size: 16px; 
                        line-height: 22px; 
                    }

                    h3 {
                        font-size: 30px; 
                        line-height: 40px;
                    }

                    h4 {
                        font-size: 60px;
                    }

                    button {
                        width: 20%;
                    }
                }
            }
        }
    }
}
