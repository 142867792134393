.our-process-container {
    background-color: var(--white-color);
    padding: 2rem;
    margin: 0 auto;
    gap: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 2rem;
        padding: 3rem 7rem 0rem 7rem;
        text-align: left;
        width: 100%;

        p {
            margin: 0;
            font-size: 64px;
            line-height: 80.64px;
            color: var(--secondary-color);
            font-weight: 600;
        }
    }

    .process-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;

        .process-card {
            display: flex;
            flex-direction: column;
            gap: 32px;
            border-radius: 8px;

            img {
                object-fit: cover;
                border-radius: 8px;
                width: 100px;
            }

            .user {
                display: flex;
                gap: 10px;
                align-items: center;
                padding-bottom: 1rem;
            }

            .process-info {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2px;

                h3 {
                    margin: 0;
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 45.36px;

                    &:first-of-type {
                        font-weight: bold;
                    }
                }

            }
        }
        .line-break {
            border-top: 2px solid;
            color: var(--line-break-color);
            padding: 2rem;
        }
        p {
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: var(--gray-color-secondary);
            width: 60%;
            padding-bottom: 2rem;
        }
    }

    @media (max-width: 768px) {
        padding: 1rem;

        .header {
            padding: 1rem;

            p {
                font-size: 48px;
                line-height: 60px;
                text-align: center;
            }
        }

        .process-card {
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;

            &:nth-child(even) {
                flex-direction: column;
            }

            img {
                width: 100%;
                height: auto;
            }

            .process-info {
                align-items: center;
                text-align: center;
            }

            p {
                text-align: center;
                width: 100%;
            }

            .line-break {
                width: 100%;
            }
        }

    }

    @media (max-width: 480px) {
        .header {
            p {
                font-size: 36px;
                line-height: 48px;
                text-align: center;
            }
        }

        .process-card {
            padding: 1rem;

            .user {
                padding-bottom: 3rem;

                p {
                    font-size: 14px;
                }
            }

            .process-info {
                h3 {
                    font-size: 18px;
                }
            }
        }

    }
}