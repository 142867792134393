.parent-container {
  display: flex;
  justify-content: flex-end; /* Align child elements to the right */
  padding: 20px;             /* Add padding as needed */
}

.contactContainer {
  width: 100%;                /* Full width of its parent */
  max-width: 700px;           /* Maximum width to ensure responsiveness */
  padding: 50px;
  background: linear-gradient(to bottom, #353995, #010103);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  height: auto;               /* Adjust height as needed */
}

/* Media query for tablets */
@media (max-width: 768px) {
  .contactContainer {
    padding: 30px;            /* Adjust padding for smaller screens */
    max-width: 90%;           /* Adjust width for smaller screens */
  }
}

/* Media query for mobile devices */
@media (max-width: 480px) {
  .contactContainer {
    padding: 20px;            /* Adjust padding for smaller screens */
    max-width: 100%;          /* Full width for mobile screens */
  }
}

.firstsec {
  width: 100%;                /* Adjust to full width of the container */
  margin-bottom: 30px;
}

.row {
  display: flex;
  align-items: center;
  gap: 80px;                  /* Adjust the gap between items */
}

/* Media query for smaller screens */
@media (max-width: 480px) {
  .row {
    flex-direction: column;   /* Stack items vertically on mobile screens */
    gap: 20px;                /* Reduce gap between items */
  }
}

.contact-item {
  margin-bottom: 30px;

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  div {
    display: flex;
    flex-direction: column;

    h3 {
      margin: 0;
      font-size: 18px;
      color: #ffffff;
    }

    p {
      margin: 0;
      font-size: 16px;
      color: #ffffff;
    }
  }
}

.social-media {
  display: flex;
  gap: 10px;

  .icon {
    width: 24px;
    height: 24px;
  }
}