.why-choose-use-container {
  padding: 4rem 2rem 2rem;
  text-align: center;
  width: 100%;
  margin: 0 auto;

  .header {
    margin-bottom: 2rem;
    p {
      text-align: left;
      margin: 0;
      font-size: 64px;
      line-height: 80.64px;
      color: var(--secondary-color);
      font-weight: 600;
    }
  }

  .choose-list {
    display: flex;
    gap: 80px;
    margin-top: 5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .choose-wrapper {
      display: flex;
      align-items: flex-start;
      width: 70%; /* Adjust width to include both logo and choose-card */
      position: relative;

      .logo {
        flex: 0 0 20%; /* Occupy 20% width */
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
          height: auto;
        }
      }

      .choose-card {
        flex: 1; /* Take remaining space */
        display: flex;
        flex-direction: column;
        gap: 32px;
        border-radius: 8px;
        text-align: left;
        padding-left: 1rem; /* Space between logo and card */

        .choose-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .flex {
            display: flex;
            align-items: center;
            gap: 15px;

            img {
              max-width: 56px;
              height: auto;
            }

            h3 {
              margin: 0;
              font-size: 40px;
              font-weight: 600;
              line-height: 50px;
              margin-left: 10px;
            }
          }

          p {
            margin-top: 1rem;
            font-size: 16px;
            line-height: 1.5;
            padding-left: 5%;
            width: 85%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .why-choose-use-container {
    padding: 2rem 1rem;
    .header p {
      font-size: 48px;
      line-height: 60px;
    }

    .choose-list {
      gap: 40px;
      .choose-wrapper {
        width: 80%;
        .logo {
          flex: 0 0 20%;
        }

        .choose-card {
          padding-left: 0.5rem; /* Adjust padding for smaller screens */
          .choose-info {
            .flex {
              gap: 10px;
              img {
                max-width: 40px;
              }
            }

            h3 {
              font-size: 30px;
              line-height: 38px;
            }
          }

          p {
            font-size: 14px;
            line-height: 1.4;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .why-choose-use-container {
    padding: 1rem;
    .header p {
      font-size: 36px;
      line-height: 44px;
    }

    .choose-list {
      gap: 20px;
      .choose-wrapper {
        width: 90%;
        .logo {
          flex: 0 0 20%;
        }

        .choose-card {
          padding-left: 0.5rem; /* Adjust padding for smaller screens */
          .choose-info {
            .flex {
              gap: 8px;
              img {
                max-width: 30px;
              }
            }

            h3 {
              font-size: 24px;
              line-height: 30px;
            }
          }

          p {
            font-size: 12px;
            line-height: 1.3;
          }
        }
      }
    }
  }
}
